<template>
    <div class="ipad-page display-case-list">
        <div class="inner">
            <div class="display-section pb-2">
                <button-wrapper
                    class="sm:hidden mb-2"
                    color="primary"
                    @click="showFilterTool = !showFilterTool"
                >
                    {{ showFilterTool ? '收合' : '點此篩選' }}
                </button-wrapper>
                <el-collapse-transition>
                    <div v-show="showFilterTool">
                        <div class="toolbar max-sm:flex-col gap-2">
                            <div class="title">
                                <div>請於下方選擇欲篩選的案例類型</div>
                            </div>
                            <div class="search">
                                <el-input
                                    v-model="searchKeyword"
                                    placeholder="搜尋關鍵字"
                                    clearable
                                    :suffix-icon="searchKeyword ? undefined : 'el-icon-search'"
                                    @change="onTextFilterChange"
                                ></el-input>
                            </div>
                        </div>
                        <div class="toolbar">
                            <div class="title w-full">
                                <div>
                                    <FilterTool
                                        :rows="config"
                                        :filter="filter"
                                        @change="onFilterChange"
                                        @clear="onFilterClear"
                                    />
                                </div>
                            </div>
                        </div>
                    </div>
                </el-collapse-transition>
                <SalesCase
                    :data="list"
                    :on-update="handleInfinite"
                    @go-to-case="onViewCase"
                    caseRouteName="Line_Doctor_Case"
                >
                    <template #footer>
                        <div
                            class="flex max-sm:flex-col items-center gap-1 bottom-0 text-sm py-2 flex-1 max-sm:justify-end"
                        >
                            <a
                                href="https://www.roche.com.tw/zh/privacy-policy.html"
                                class="privacy"
                            >
                                隱私聲明
                            </a>
                            <div class="AE cursor-pointer" @click="onOpenAEPopup">AE通報資訊</div>
                            <div
                                class="reveal-information cursor-pointer"
                                @click="onOpenRevealInformationPopup"
                            >
                                羅氏揭露訊息
                            </div>
                        </div>
                    </template>
                </SalesCase>
            </div>
        </div>
    </div>
</template>

<script>
import FilterTool from '@/components/CaseFilter/FilterTool.vue';
import SalesCase from '@/components/Table/SalesCase.vue';
import { mapActions, mapMutations, mapState } from 'vuex';

export default {
    components: { SalesCase, FilterTool },
    data() {
        return {
            loading: false,
            searchKeyword: '',
            showFilterTool: false,
            value: undefined,
            filter: undefined
        };
    },
    computed: {
        ...mapState({
            list: state => state.display.caseList,
            filterState: state => state.display.filterState.value,
            config: state => state.config.filterTag
        })
    },
    created() {
        this.fetchFilter(this.$route.query);
    },
    mounted() {
        this.showFilterTool = window.innerWidth > 640;
    },
    destroyed() {
        this.clear();
    },
    methods: {
        ...mapMutations({
            updateGenerateLinkAndPasswordPopUpState:
                'popup/updateGenerateLinkAndPasswordPopUpState',
            updateAEDisclaimerPopUpState: 'popup/updateAEDisclaimerPopUpState',
            updateRevealInformationPopupState: 'popup/updateRevealInformationPopupState',

            updateConfirmationState: 'popup/updateConfirmationState',
            updateConfirmationText: 'popup/updateConfirmationText',
            updateConfirmationFunction: 'popup/updateConfirmationFunction',

            setMode: 'form/setMode',

            updateFilterText: 'display/updateFilterText',
            updateFilterData: 'display/updateFilterData',
            increasePage: 'display/increasePage',
            clear: 'display/clear',
            setTarget: 'display/setTarget'
        }),
        ...mapActions({
            apiProxy: 'api/apiProxy',
            fetchQuery: 'display/fetchQuery',
            updateFilter: 'display/updateFilter',
            onSearch: 'display/onSearchDisplayCase'
        }),
        async handleInfinite($state) {
            try {
                this.loading = true;
                this.fetchQuery();
                const RES = await this.onSearch();
                this.increasePage();
                if (RES) $state.loaded();
                else $state.complete();
            } catch (e) {
                console.log(e);
            } finally {
                this.loading = false;
            }
        },
        fetchFilter(filter) {
            this.filter = filter;
        },
        onFilterClear(filter) {
            this.updateFilterData(filter);
            this.updateFilter();
        },
        onFilterChange(filter) {
            if (!filter) return;
            this.updateFilterData(filter);
            this.updateFilter();
        },
        onTextFilterChange(text) {
            this.updateFilterText(text);
            this.updateFilter();
        },
        onOpenAEPopup() {
            this.updateAEDisclaimerPopUpState(true);
        },
        onOpenRevealInformationPopup() {
            this.updateRevealInformationPopupState(true);
        },
        onViewCase(id) {
            const PAYLOAD = {
                category: 'Case',
                function: 'addLineViewCount',
                data: id
            };
            this.apiProxy(PAYLOAD);
        }
    }
};
</script>

<style lang="scss" scoped>
.display-case-list {
    position: relative;
    padding-bottom: 0;
    background: url('/bg/hcc-m-top.png') no-repeat top/contain fixed !important;

    // background-image: url('/bg/hcc-m-top.png'), url('/bg/hcc-m-bottom.png') !important;
    // background-repeat: no-repeat !important;
    // background-position: top, bottom !important;
    // background-size: contain !important;
    // background-attachment: fixed !important;

    @media screen and (min-width: 640px) {
        background: url('/bg/hcc.jpg') no-repeat center/contain fixed !important;
    }
    .title {
        font-size: 20px;
    }
}
</style>
